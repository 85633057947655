.amount {
  border-radius: 5px;
  text-align: right;
  margin-bottom: 10px;
}

.processTitle {
  box-sizing: border-box;
  margin: 0;
  color: #fff;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 0.5;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  padding: 8px 24px;
  background-color: #fff;
  border: 1px solid rgb(235, 237, 240);
  border-radius: 5px;
  background: rgb(103, 181, 255);
  background: linear-gradient(
    90deg,
    rgba(103, 181, 255, 1) 0%,
    rgba(84, 172, 254, 1) 40%,
    rgba(0, 212, 255, 1) 100%
  );
}

.firstColumn {
  padding-bottom: 15px;
  padding-top: 15px;
}

.saveButton {
  background: #50ff41;
  border-color: #50ff41;

  &:hover,
  &:active,
  &:focus {
    background: #50ff41;
    border-color: #50ff41;
  }
}

.stepsContent {
    margin-top: 16px;
    border: 1px dashed #ffffff;
    border-radius: 6px;
    background-color: #ffffff;
    min-height: 200px;
    padding-top: 80px;
}

.stepsAction {
    margin-top: 24px;
}
